<template>
  <v-container fill-height fluid style="background-color: #ebedf3" class="pa-0">
    <v-row
      class="pt-0"
      no-gutters
      justify="center"
      v-if="leilaoSelecionado.onlineYoutube"
    >
      <v-sheet color="#FFF" class="pa-2 px-6" rounded="">
        <v-row>
          <router-link to="aovivo" target="_blank">
            <v-img
              :src="require('@/assets/banner_aovivo.png')"
              width="180"
            ></v-img>
          </router-link>
        </v-row>
      </v-sheet>
    </v-row>

    <v-row>
      <!-- PRÓXIMOS LOTES -->
      <v-col
        cols="12"
        sm="6"
        md="3"
        lg="3"
        xl="3"
        class="py-5 pb-0"
        :order="mobile ? 2 : 1"
        align="center"
      >
        <h3 class="text-center">PRÓXIMOS LOTES ({{ totalAndamento }})</h3>

        <div
          id="virtual"
          class="virtual-scroll col-lotes"
          v-scroll:#virtual="onScroll"
        >
          <CardLote
            v-for="(lote, i) in proximosLotes"
            :key="i"
            :lote="lote"
            :foto="false"
            @click="mostrarLote(lote)"
          />

          <div class="text-center">
            <v-btn icon :loading="loadingProximosLotes"> </v-btn>
          </div>
        </div>
      </v-col>

      <!-- EM LEILÃO -->
      <v-col
        cols="12"
        md="6"
        lg="6"
        xl="6"
        class="py-5 pb-0"
        :order="mobile ? 1 : 2"
      >
        <v-row justify="space-between">
          <h3>LOTE SELECIONADO</h3>
          <span style="color: #2c75ea; font-weight: bold">{{
            leilaoSelecionado.nome
          }}</span>
        </v-row>

        <v-card elevation="0" rounded="lg">
          <v-card-text>
            <v-row
              v-show="mobile"
              class="mx-0 mb-1 mobile"
              justify="space-between"
            >
              <div class="d-flex align-center">
                <span class="text--secondary mr-2">TEMPO RESTANTE: </span
                ><span
                  :style="
                    emLeilao && emLeilao.info.emFechamento
                      ? 'color: #ff5252 !important'
                      : 'color: #bbbbbb'
                  "
                  class="text-body-1"
                >
                  <span class="countDown" v-if="emLeilao && countStatus">{{
                    countStatus
                  }}</span>
                  <span
                    v-if="
                      emLeilao &&
                      emLeilao.info &&
                      emLeilao.info.status.includes('Aguardando') &&
                      !countStatus
                    "
                    >{{ tempoRestante }}</span
                  >
                  <span
                    style="color: #2c75ea"
                    v-if="
                      emLeilao &&
                      emLeilao.info &&
                      emLeilao.info.status == 'Encerrado' &&
                      !countStatus
                    "
                  >
                    Encerrado
                  </span>
                </span>

                <count-down
                  v-if="emLeilao && emLeilao.info.emFechamento && !countStatus"
                  :final="emLeilao.info.dataEncerramento"
                  :atual="emLeilao.info.dataAtualUtc"
                  @FinishCountdown="onFinishCountDown"
                />
              </div>
            </v-row>
            <v-row justify="space-between" class="ma-0">
              <div class="card-info">
                <p class="text--secondary">LOTE</p>
                <v-sheet height="20" v-if="!emLeilao">
                  <v-skeleton-loader
                    type="card"
                    height="20"
                  ></v-skeleton-loader>
                </v-sheet>
                <p class="font-weight-black" v-else>
                  {{ emLeilao.numeroLote }}
                </p>
              </div>
              <div class="card-info">
                <p class="text--secondary">LANCE ATUAL</p>
                <v-sheet height="20" width="100" v-if="!emLeilao">
                  <v-skeleton-loader
                    type="card"
                    width="100"
                    height="20"
                  ></v-skeleton-loader>
                </v-sheet>
                <p class="font-weight-black" v-else>
                  R$ {{ emLeilao && emLeilao.info.ultimoLance | formatMoeda }}
                </p>
              </div>
              <div class="card-info">
                <p class="text--secondary">USUÁRIO</p>
                <v-sheet height="20" v-if="!emLeilao">
                  <v-skeleton-loader
                    type="card"
                    height="20"
                  ></v-skeleton-loader>
                </v-sheet>
                <p class="font-weight-black" v-else>
                  {{ (emLeilao && emLeilao.info.usuarioUltimoLance) || '-' }}
                </p>
              </div>
              <div class="card-info hidden-sm-and-down">
                <p class="text--secondary">TEMPO RESTANTE</p>
                <p
                  class="font-weight-black text-right"
                  :style="
                    emLeilao && emLeilao.info.emFechamento
                      ? countStatus === 'Homologando...'
                        ? 'color: #f9c718 !important'
                        : 'color: #e0280f !important'
                      : 'color: #bbbbbb'
                  "
                >
                  <span
                    v-if="
                      ((emLeilao &&
                        emLeilao.info &&
                        emLeilao.info.status.includes('Aguardando')) ||
                        (emLeilao &&
                          emLeilao.info &&
                          emLeilao.info.status.includes('Aberto'))) &&
                      !countStatus
                    "
                    >{{ tempoRestante }}</span
                  >
                  <span v-else-if="emLeilao && countStatus">{{
                    countStatus
                  }}</span>
                  <span
                    style="color: #2c75ea"
                    v-if="
                      emLeilao &&
                      emLeilao.info &&
                      emLeilao.info.status == 'Encerrado' &&
                      !countStatus
                    "
                  >
                    Encerrado
                  </span>
                  <count-down
                    v-else-if="
                      emLeilao && emLeilao.info.emFechamento && !countStatus
                    "
                    :final="emLeilao.info.dataEncerramento"
                    :atual="emLeilao.info.dataAtualUtc"
                    @FinishCountdown="onFinishCountDown"
                  />
                </p>
              </div>
            </v-row>
            <div class="virtual-scroll-leilao col-leilao">
              <v-row>
                <v-col
                  cols="12"
                  md="8"
                  lg="8"
                  xl="8"
                  class="pl-0"
                  :class="mobile && 'pa-0 pt-3'"
                >
                  <v-card rounded="lg" class="clicavel">
                    <v-carousel hide-delimiters height="260px">
                      <v-carousel-item v-if="loading">
                        <v-sheet class="pa-3">
                          <v-skeleton-loader
                            class="mx-auto"
                            type="image"
                          ></v-skeleton-loader>
                        </v-sheet>
                      </v-carousel-item>
                      <v-carousel-item
                        v-else
                        v-for="(foto, i) in emLeilao.fotos"
                        :key="i"
                        :src="foto && foto.arquivo.url"
                        cover
                        width="calc(100vw - 55px)"
                        @click="zoom(foto && foto.arquivo.url, i)"
                      >
                        <span class="tag-leilao">
                          {{ emLeilao && emLeilao.descricao }}
                        </span>
                      </v-carousel-item>
                    </v-carousel>
                  </v-card>

                  <v-row class="mt-2 hidden-sm-and-down" v-if="loading">
                    <v-col
                      cols="6"
                      md="4"
                      lg="4"
                      xl="4"
                      v-for="i in 3"
                      :key="i"
                    >
                      <v-card>
                        <v-responsive :aspect-ratio="16 / 9">
                          <v-skeleton-loader
                            class="mx-auto"
                            max-width="250"
                            max-height="80"
                            type="image"
                          ></v-skeleton-loader>
                        </v-responsive>
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-row class="mt-2 hidden-sm-and-down" v-else>
                    <v-col
                      cols="6"
                      md="4"
                      lg="4"
                      xl="4"
                      v-for="(foto, index) in emLeilao.fotos"
                      :key="index"
                    >
                      <v-card class="clicavel" v-if="index < 3">
                        <v-responsive :aspect-ratio="16 / 9">
                          <v-img
                            max-height="150"
                            max-width="250"
                            :src="foto && foto.arquivo.url"
                            contain
                            @click="zoom(foto && foto.arquivo.url, index)"
                          ></v-img>
                        </v-responsive>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                  lg="4"
                  xl="4"
                  class="caracteristicas px-0"
                >
                  <v-card
                    class="pa-3"
                    v-show="
                      emLeilao &&
                      emLeilao.info.status != 'Encerrado' &&
                      countStatus !== 'Homologando...'
                    "
                  >
                    <h4>PRÓXIMO LANCE</h4>
                    <h2 class="mb-4">
                      {{
                        valor
                          | currency('R$', 0, {
                            spaceBetweenAmountAndSymbol: true,
                            decimalSeparator: ',',
                            thousandsSeparator: '.',
                          })
                      }}
                    </h2>

                    <p class="mb-2">
                      Incremento:
                      <b>
                        {{
                          emLeilao &&
                          emLeilao.valorIncremento
                            | currency('R$', 0, {
                              spaceBetweenAmountAndSymbol: true,
                              decimalSeparator: ',',
                              thousandsSeparator: '.',
                            })
                        }}</b
                      >
                    </p>
                    <v-row no-gutters v-if="leilaoSelecionado.lanceParcelado">
                      <v-col cols="12">
                        <v-switch
                          v-model="lanceParcelado"
                          label="Lance Parcelado"
                          inset
                        ></v-switch>
                      </v-col>
                    </v-row>

                    <v-container
                      v-if="lanceParcelado"
                      fluid
                      class="py-0 px-0 mb-2"
                    >
                      <v-row no-gutters class="mb-1">
                        <v-col cols="12">
                          <vuetify-money
                            v-model="valorEntrada"
                            label="Valor da Entrada"
                            dense
                            outlined
                            v-bind:options="options"
                          />
                        </v-col>
                      </v-row>
                      <v-row no-gutters class="mb-1">
                        <v-col cols="12">
                          <v-select
                            v-model="parcelas"
                            :items="qtdParcelas"
                            label="Parcelas"
                            dense
                            outlined
                          ></v-select>
                        </v-col>
                      </v-row>
                      <v-row no-gutters>
                        <v-col cols="12">
                          <v-select
                            v-model="indiceCorrecao"
                            :items="
                              leilaoSelecionado.configuracaoParcela
                                .indiceCorrecao
                            "
                            item-text="indiceCorrecao.descricao"
                            item-value="indiceCorrecaoId"
                            outlined
                            dense
                            label="Índice de Correção"
                          >
                            <template v-slot:item="{ item }">
                              <span>
                                {{
                                  item.indiceCorrecao.descricao +
                                  ' (' +
                                  item.indiceCorrecao.indiceCorrecao +
                                  '%)'
                                }}
                              </span>
                            </template>
                          </v-select>
                        </v-col>
                      </v-row>
                    </v-container>
                    <v-row justify="space-between">
                      <v-btn
                        color="success"
                        :small="!mobile"
                        class="square"
                        title="Incremento"
                        @click="incremento"
                      >
                        <v-icon> mdi-plus </v-icon>
                      </v-btn>
                      <v-btn
                        color="warning"
                        :small="!mobile"
                        class="square"
                        title="Decremento"
                        @click="decremento"
                      >
                        <v-icon> mdi-minus </v-icon>
                      </v-btn>
                      <v-btn
                        color="primary"
                        :small="!mobile"
                        title="Dar Lance"
                        @click="fazerLance"
                      >
                        Lance
                        <v-icon class="ml-2">
                          mdi-arrow-right-circle-outline
                        </v-icon>
                      </v-btn>
                    </v-row>
                  </v-card>

                  <div class="pt-3">
                    <v-expansion-panels>
                      <v-expansion-panel>
                        <v-expansion-panel-header
                          class="text-caption"
                          style="color: #2c75ea; font-weight: bold"
                        >
                          MAIS INFORMAÇÕES
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <p class="text-center mb-2">
                            {{ emLeilao && emLeilao.descricao }}
                          </p>
                          <p
                            v-for="(item, i) in emLeilao && emLeilao.campos"
                            :key="i"
                            style="line-height: 14px"
                          >
                            <span class="font-weight-bold"
                              >{{ item.loteCampo.descricao }}: </span
                            ><span class="text--secondary">{{
                              item.valor
                            }}</span>
                          </p>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </div>

                  <v-sheet elevation="2" class="mt-3">
                    <v-row>
                      <v-col>
                        <div class="d-flex align-center my-2">
                          <div class="mr-4">
                            <img
                              :src="require('@/assets/icon1.png')"
                              style="width: 40px"
                            />
                          </div>

                          <div>
                            <p class="font-weight-black text-h4">
                              {{ emLeilao && emLeilao.info.qtdParticipantes }}
                            </p>
                            <p class="text-caption">Participantes</p>
                          </div>
                        </div>

                        <div class="d-flex align-center mb-2">
                          <div class="mr-4">
                            <img
                              :src="require('@/assets/icon2.png')"
                              style="width: 40px"
                            />
                          </div>

                          <div>
                            <p class="font-weight-black text-h4">
                              {{ emLeilao && emLeilao.info.qtdVisualizacoes }}
                            </p>
                            <p class="text-caption">Visualizações</p>
                          </div>
                        </div>

                        <div class="d-flex align-center mb-2">
                          <div class="mr-4">
                            <img
                              :src="require('@/assets/icon3.png')"
                              style="width: 40px"
                            />
                          </div>

                          <div>
                            <p class="font-weight-black text-h4">
                              {{ emLeilao && emLeilao.info.qtdLances }}
                            </p>
                            <p class="text-caption">Lances</p>
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                  </v-sheet>
                </v-col>
              </v-row>
              <v-row v-if="emLeilao && emLeilao.descricaoDetalhada">
                <v-col>
                  <p class="font-weight-black text-caption">
                    DESCRIÇÃO DETALHADA
                  </p>
                  <p
                    class="text--secondary text-caption"
                    v-html="emLeilao.descricaoDetalhada"
                  ></p>
                </v-col>
              </v-row>
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- LOTES LEILOADOS -->
      <v-col
        cols="12"
        sm="6"
        md="3"
        lg="3"
        xl="3"
        class="py-5 pb-0"
        align="center"
        order="3"
      >
        <h3>LOTES LEILOADOS ({{ totalLeiloados }})</h3>

        <div
          id="virtualLeiloados"
          class="col-lotes virtual-scroll"
          v-scroll:#virtualLeiloados="onScrollLeiloados"
        >
          <CardLote
            v-for="(lote, i) in lotesLeiloados"
            :key="i"
            :lote="lote"
            :foto="false"
            @click="mostrarLote(lote)"
          />
          <v-btn icon :loading="loadingLotesLeiloados"> </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-dialog v-model="zoomImg" max-width="720" max-height="360" v-if="zoomImg">
      <v-carousel v-model="itemCarosel">
        <v-carousel-item
          v-for="(foto, i) in emLeilao.fotos"
          :key="i"
          :src="foto && foto.arquivo.url"
          aspect-ratio="2"
        ></v-carousel-item>
      </v-carousel>
    </v-dialog>
  </v-container>
</template>

<script>
import Leilao from '@/service/leilao';
import CardLote from '../components/card-lote.vue';
import CountDown from '../components/CountDown.vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import moment from 'moment';
import sweetalertMixins from '../../mixins/sweetalertMixin';

export default {
  name: 'AuditorioVirtual',
  components: {
    CardLote,
    CountDown,
  },
  mixins: [sweetalertMixins],
  data() {
    return {
      zoomImg: false,
      urlImg: '',
      itemCarosel: 0,
      contadorLote: {
        total: 0,
      },
      contadores: {
        lances: 0,
      },
      leiloes: [],
      leilaoSelecionado: {},
      lotes: null,
      leiloados: null,
      emLeilao: null,
      proximosLotes: [],
      lotesLeiloados: [],
      loadingProximosLotes: false,
      loadingLotesLeiloados: false,
      countStatus: '',
      loading: true,
      mostrarIniciar: false,
      encerrando: false,
      totalLeiloados: 0,
      totalAndamento: 0,
      valor: 0,
      lanceParcelado: false,
      valorEntrada: 0,
      indiceCorrecao: 0,
      parcelas: 0,
      options: {
        locale: 'pt-BR',
        prefix: 'R$',
        suffix: '',
        length: 11,
        precision: 2,
      },
    };
  },

  computed: {
    ...mapGetters([
      'getAcao',
      'getAcaoLoteAtivo',
      'getLeilao',
      'getLanceError',
    ]),
    widownHeight() {
      return window.innerHeight * 0.9;
    },
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    tempoRestante() {
      const tempo = this.leilaoSelecionado.tempoInicioSeg;
      const format = tempo >= 60 ? 'm[m] : s[s]' : 's[s]';
      return moment('00:00', 'mm:ss').add(tempo, 's').format(format);
    },
    qtdParcelas() {
      const parcelas = [];
      for (
        let i = 1;
        i <= this.leilaoSelecionado.configuracaoParcela.maximoParcelas;
        i++
      ) {
        parcelas.push(i);
      }

      return parcelas.reverse();
    },
  },

  methods: {
    ...mapActions([
      'setAuth',
      'iniciar',
      'parar',
      'encerrar',
      'getLoteInfo',
      'lance',
    ]),
    ...mapMutations([
      'setTituloPagina',
      'SET_SIGNAL',
      'SET_LOTEID_SELECIONADO',
      'SET_LEILAO',
    ]),
    zoom(url, index) {
      this.urlImg = url;
      this.itemCarosel = parseInt(index);
      this.zoomImg = true;
    },
    mostrarLote(lote) {
      this.countStatus = null;
      this.$nextTick(() => this.setEmleilao(lote));
    },

    async acaoAoEncerrar() {
      if (this.proximosLotes.length > 1) {
        this.setEmleilao(this.proximosLotes[1]);
      }
      const { id } = this.leilaoSelecionado;
      const lotes = await Leilao.getLotes({ id, page: 1, status: 'aberto' });
      this.lotes = lotes;
      this.proximosLotes = lotes.data;
      this.totalAndamento = lotes.totalRecords;
      const leiloados = await Leilao.getLotes({
        id,
        page: 1,
        status: 'encerrado',
      });

      this.lotesLeiloados = leiloados.data;
      this.totalLeiloados = leiloados.totalRecords;

      this.encerrando = false;
    },
    async addLotes(id) {
      //console.log(id)
      let page = this.lotes.pageNumber + 1;

      if (page > 1 && this.lotes && page > this.lotes.totalPages) return;

      this.loadingProximosLotes = true;
      const lotes = await Leilao.getLotes({ id, page, status: 'aberto' });
      this.lotes = lotes;

      lotes.data.forEach((e) => this.proximosLotes.push(e));
      this.totalAndamento = lotes.totalRecords;

      this.loadingProximosLotes = false;
    },
    async addLotesLeiloados(id) {
      const page = this.leiloados.pageNumber + 1;

      if (page > 1 && this.leiloados && page > this.leiloados.totalPages)
        return;

      this.loadingLotesLeiloados = true;
      const lotes = await Leilao.getLotes({ id, page, status: 'encerrado' });
      this.leiloados = lotes;
      this.totalLeiloados = lotes.totalRecords;

      lotes.data.forEach((e) => this.lotesLeiloados.push(e));

      this.loadingLotesLeiloados = false;
    },
    async onChangeLeilao() {
      this.countStatus = '';
      const { id } = this.leilaoSelecionado;
      this.lotes = null;
      await this.addLotes(id);
      this.setEmleilao();
      await this.addLotesLeiloados(id);
    },
    async changeLeilao() {
      this.lotes = null;
      this.countStatus = '';
      const { id } = this.leilaoSelecionado;
      this.onJoinGroup(id);
      this.loadingProximosLotes = true;
      const lotes = await Leilao.getLotes({ id, page: 1, status: 'aberto' });
      this.lotes = lotes;
      this.totalAndamento = lotes.totalRecords;
      this.proximosLotes = lotes.data;
      this.loadingProximosLotes = false;

      this.setEmleilao();

      this.loadingLotesLeiloados = true;
      const lotesLeiloados = await Leilao.getLotes({
        id,
        page: 1,
        status: 'encerrado',
      });

      this.totalLeiloados = lotesLeiloados.totalRecords;
      this.lotesLeiloados = lotesLeiloados.data;
      this.loadingLotesLeiloados = false;
    },
    onFinishCountDown(finalizar) {
      if (finalizar) {
        this.countStatus = 'Homologando...';
      }
    },
    async setEmleilao(item = null) {
      this.loading = true;
      this.countStatus = null;
      if (!item) {
        item =
          this.proximosLotes.find((e) => e.info.emFechamento) ||
          this.proximosLotes[0];
      }

      const { data } = await Leilao.getLote({ id: item.loteId });
      const resp = await Leilao.getLoteInfo({ id: item.loteId });
      this.emLeilao = data;
      this.emLeilao['info'] = { ...resp.data };
      this.emLeilao['descricaoDetalhada'] = data.descricaoDetalhada;
      this.emLeilao['fotos'] = {
        ...data.fotos.filter((e) => e.tipoFoto.visivelSite),
      };
      this.loading = false;
      this.$nextTick(() => {
        this.valor =
          this.emLeilao.info.ultimoLance + this.emLeilao.valorIncremento;
      });
      return;
    },

    async inicializa() {
      try {
        const resp = await Leilao.getLeilao(this.getLeilao.id);
        this.SET_LEILAO(resp.data);
        this.leilaoSelecionado = resp.data;
        this.$nextTick(() => {
          this.changeLeilao();
          this.onJoinGroup(this.leilaoSelecionado.id);
        });
      } catch (_) {
        //console.error();
      }
    },
    onScroll(e) {
      this.top = e.target.scrollTop;
      const elem = document.getElementById('virtual');

      if (elem.offsetHeight + elem.scrollTop >= elem.scrollHeight) {
        this.toTop = true;
        const { id } = this.leilaoSelecionado;
        this.addLotes(id);
        return;
      }
      this.toTop = false;
    },
    onScrollLeiloados(e) {
      this.top = e.target.scrollTop;
      const elem = document.getElementById('virtualLeiloados');

      if (elem.offsetHeight + elem.scrollTop >= elem.scrollHeight) {
        this.toTop = true;
        const { id } = this.leilaoSelecionado;
        this.addLotesLeiloados(id);
        return;
      }
      this.toTop = false;
    },
    onJoinGroup(leilaoId) {
      this.$signalR.joinLeilao(leilaoId);
    },
    async onEventNewLance(loteinfo) {
      if (this.emLeilao.loteId == loteinfo.loteId) {
        this.countStatus = '';

        this.valor = loteinfo.ultimoLance + this.emLeilao.valorIncremento;

        if (loteinfo.status === 'Encerrado') {
          this.acaoAoEncerrar();
        }
        this.emLeilao['info'] = { ...loteinfo };
      }
      const lote = this.proximosLotes.find((e) => e.loteId == loteinfo.loteId);
      if (lote) {
        lote.info = { ...loteinfo };
      }
      this.countStatus = null;
      if (this.proximosLotes.length == 0) {
        this.confirm('Leilão encerrado! deseja fechar tela').then((confirm) => {
          if (confirm.isConfirmed) {
            window.close();
          }
        });
      }
    },
    async incremento() {
      const { ultimoLance } = this.emLeilao.info;
      const { valorIncremento } = this.emLeilao;

      if (this.valor === 0 || this.valor < ultimoLance) {
        this.valor = ultimoLance;
      }

      this.valor += valorIncremento;
    },
    async decremento() {
      if (typeof this.valor !== 'number') return;

      const { ultimoLance } = this.emLeilao.info;
      const { valorIncremento } = this.emLeilao;

      const valor = this.valor - this.emLeilao.valorIncremento;

      if (valor < ultimoLance + valorIncremento) return;

      if (valor < 0) return;
      this.valor = valor;
    },
    async fazerLance() {
      if (!this.valor || typeof this.valor !== 'number' || this.valor < 0)
        return;

      const { ultimoLance } = this.emLeilao.info;
      const { valorIncremento } = this.emLeilao;

      if (this.valor <= ultimoLance) {
        this.$store.commit('SET_LANCE_ERROR', [
          'Valor do lance deve ser maior que o último lance',
        ]);
        this.showToast({
          type: 'error',
          title: 'Valor do lance deve ser maior que o último lance',
        });
        return;
      }

      if (this.valor - ultimoLance < valorIncremento) {
        this.$store.commit('SET_LANCE_ERROR', [
          'A diferença entre seu lance e o último lance deve ser maior ou igual o incremento mínimo',
        ]);
        this.showToast({
          type: 'error',
          title:
            'A diferença entre seu lance e o último lance deve ser maior ou igual o incremento mínimo',
        });
        return;
      }

      if (this.lanceParcelado) {
        if (
          this.valorEntrada <= 0 ||
          this.valorEntrada <
            this.valor *
              (this.leilaoSelecionado.configuracaoParcela.minimoEntrada / 100)
        ) {
          this.$store.commit('SET_LANCE_ERROR', [
            'O Valor da Entrada deve ser maior ou igual a ' +
              this.leilaoSelecionado.configuracaoParcela.minimoEntrada +
              '% do valor do Lance',
          ]);
          this.showToast({
            type: 'error',
            title:
              'O Valor da Entrada deve ser maior ou igual a ' +
              this.leilaoSelecionado.configuracaoParcela.minimoEntrada +
              '% do valor do Lance',
          });
          return;
        }

        if (this.parcelas <= 0) {
          this.$store.commit('SET_LANCE_ERROR', [
            'Selecione o numero de parcelas desejada para o lance parcelado',
          ]);
          this.showToast({
            type: 'error',
            title:
              'Selecione o numero de parcelas desejada para o lance parcelado',
          });
          return;
        }

        if (this.indiceCorrecao <= 0) {
          this.$store.commit('SET_LANCE_ERROR', [
            'Selecione o índice de correção desejada para o lance parcelado',
          ]);
          this.showToast({
            type: 'error',
            title:
              'Selecione o índice de correção desejada para o lance parcelado',
          });
          return;
        }
      }

      const payload = {
        loteId: this.emLeilao.loteId,
        valor: this.valor,
        lanceParcelado: this.lanceParcelado,
        valorEntrada: this.valorEntrada,
        parcelas: this.parcelas,
        indiceCorrecaoId: this.indiceCorrecao,
      };

      await this.lance(payload);

      if (this.getLanceError) {
        this.showToast({
          type: 'error',
          title: this.getLanceError,
        });
      } else {
        this.lanceParcelado = false;
        this.valorEntrada = 0;
        this.parcelas = 0;
        this.indiceCorrecaoId = 0;
      }
    },
  },
  beforeDestroy() {
    this.$signalR.$off('new-lance', this.onEventNewLance);
  },
  filters: {
    formataData(val) {
      if (!val) return '';
      return new Date(val).toLocaleString();
    },
    formatMoeda(val) {
      if (!val) return '';
      return val.toLocaleString('pt-br', { minimumFractionDigits: 0 });
    },
  },
  async created() {
    this.setTituloPagina('AUDITÓRIO VIRTUAL');
    await this.inicializa();
    document.body.scrollTop = 0;
    this.$signalR.$on('new-lance', this.onEventNewLance);
  },
};
</script>

<style>
p {
  margin: 0px !important;
}

.card-info div {
  display: flex;
  justify-content: flex-end;
}

.card-info .text--secondary {
  font-size: 11px;
}

.card-info .font-weight-black {
  font-size: 20px;
}

.caracteristicas span {
  font-size: 11px;
}

.clicavel {
  cursor: pointer;
}

.painel {
  font-size: 13px;
}

.mobile .text--secondary {
  font-size: 15px;
}

.countDown {
  font-size: 16px;
}

@media only screen and (max-width: 420px) {
  .card-info .font-weight-black {
    font-size: 16px;
  }
}

.virtual-scroll {
  overflow-y: auto;
  overflow-x: hidden;
}

.virtual-scroll::-webkit-scrollbar {
  display: none;
}

.col-lotes {
  height: 350px;
}

@media only screen and (min-width: 600px) {
  .virtual-scroll-leilao {
    overflow-y: auto;
    overflow-x: hidden;
  }

  .virtual-scroll-leilao::-webkit-scrollbar {
    display: none;
  }

  .col-lotes {
    height: calc(100vh - 175px);
  }

  .col-leilao {
    height: calc(100vh - 250px);
  }
}

iframe {
  width: 100%;
  height: 100%;
}

.youtube {
  width: 320px;
  height: 180px;
  border: 3px solid #fff;
  box-shadow: 0px 7px 5px 0px #00000060;
}

.tag-leilao {
  position: absolute;
  z-index: 9999;
  padding: 8px;
  background-color: rgba(255, 255, 255, 0.7);
  color: #222;
  font-weight: bold;
  border-bottom-right-radius: 10px;
  letter-spacing: 1.5px;
}

.v-text-field__details {
  display: none;
}
</style>
